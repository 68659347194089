$screen-x-large: 9999px;
$screen-large: 2550px;
$screen-medium: 1180px;
$screen-small: 850px;

$screen-small-plus: $screen-small + 1px;
$screen-medium-plus: $screen-medium + 1px;
$screen-large-plus: $screen-large + 1px;

@mixin small {
  @media (max-width: $screen-small) {
    @content;
  }
}

@mixin medium {
  @media (min-width: $screen-small-plus) and (max-width: $screen-medium) {
    @content;
  }
}

@mixin large {
  @media (min-width: $screen-medium-plus) and (max-width: $screen-large) {
    @content;
  }
}

@mixin x-large {
  @media (min-width: $screen-large-plus) {
    @content;
  }
}

.small-device-only {
    display: none;
    @include small {
      display: block; 
    }
}

.medium-device-only {
    display: none;
    @include medium {
      display: block; 
    }
}

.large-device-only {
    display: none;
    @include large() {
      display: block; 
    }
}

.from-large-devices-up {
    display: none;
    @include large() {
      display: block; 
    }
    @include x-large() {
        display: block; 
      }
}

.from-medium-devices-up {
    display: none;
    @include medium() {
        display: block; 
    }
    @include large() {
      display: block; 
    }
    @include x-large() {
        display: block; 
    }
}

/* Global Styles */
#smooth-scroll-container {
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent native scroll */
}

/* You might need to ensure the body itself doesn't scroll */
html, body {
    height: 100%;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans', sans-serif;
    background-color: #171717;
    color: white;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    scroll-behavior: smooth;
    @include x-large {
        transform: scale(1.1); 
        transform-origin: 50% 0; 
    }

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logo{
    background: url('../assets/logo.svg') no-repeat left;
    width: 90px;
}

button {
    font-family: inherit;
    font-size: 1.0em ;
    margin-left: 20px;
    padding: 14px 15px;
    background-color: #e6e6e6;
    color: #171717;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    width: 160px;
}
.container {
    width: 90%;
    max-width: 1112px;
    margin: 0 auto;
}

p {
    color: #CCCCCC;
    font-weight: normal;
}

/* Navigation Bar */
nav {
    position: fixed;
    top: 16px;
    width: 100%;
    z-index: 1000;
}

nav .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #0a0b0cb9;
    backdrop-filter: blur(15px);
    border-radius: 16px;
    border: 1px solid #333333;
}

nav .logo {
    height: 44px;
}


nav .nav-links {
    list-style: none;
    display: flex;
    margin-left: auto;
}

nav .nav-links li {
    margin-left: 16px;
    font-size: 0.9em;
}

nav .nav-links span {
    margin-left: 16px;
    cursor: default;
}


nav .nav-links a {
    color: white;
    text-decoration: none;
    font-weight: 500;

}
nav .nav-links .dd-menu {
    color: white;
    text-decoration: none;
    font-weight: 500;

    cursor: pointer;
    position: relative;
    margin-right: 24px;;
}

nav .nav-links .dd-menu::before {
    content: "";
    position: absolute;
    top: 1px;
    right: -19px;
    width: 14px;
    height: 14px;
    background: url('../assets/arrow-item.svg') no-repeat right;
    background-size: 10px 20px;
    transform: rotate(90deg); 
    transition: transform 0.3s ease; 
}

nav .nav-links .dd-menu.rotated::before {
    transform: rotate(270deg) translateX(-4px);
}



nav .nav-buttons button:hover {
    background-color: #2ea043;
}

nav .dd-menu-panel::before {
    content: '';
    position: absolute;
    width: 100%; 
    height: calc(100% - 28px);
    background-color: black;
    border: #212121 1px solid;
    top: 32px;
    left: 0;
    z-index: -1; 
    border-radius: 10px;

}
nav .dd-menu-panel {
    width: 158px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -12px;
    top: -6px;
    transition: opacity 0.3s ease; 
    padding: 46px 12px 12px 12px;
    z-index: 100; 
    gap: 8px;


}

nav .dd-menu-panel.show {
    opacity: 1;
    visibility: visible;
}

/* Hero Section --------------------------------------------------------------*/
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 196px;
    margin-bottom: 56px;

    text-align: center;
    padding-top: 80px;
    position: relative;

    @include small {
        margin-top: 156px;
        margin-bottom: 56px;
    }
    @include medium {
        margin-top: 300px;
        margin-bottom: 256px;
    }
}

.hero h1 {
    font-size: 4em;
    margin-bottom: 20px;
    color: #ffffff;   

    @include medium {
        font-size: 3em;
    }

    @include small {
        font-size: 1.8em;
    }
}


.hero p {
    font-size: 1.4em;
    margin-bottom: 30px;
    color: #cccccc;

    @include medium {
        font-size: 1.2em;
    }

    @include small {
        font-size: 0.9em;
        padding: 0 16px;
    }
}

.hero-buttons button {
    margin: 5px;
    padding: 12px 25px;
    background-color: #238636;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
}

.hero-buttons button:nth-child(2) {
    background-color: transparent;
    border: 1px solid #30363d;
    color: #c9d1d9;
}

.hero-buttons button:hover {
    background-color: #52B382;
}

.hero-buttons button:nth-child(2):hover {
    background-color: #30363d;
}

@keyframes scroll {
    0% { opacity: 0; }
    50% { opacity: 1; top: 15px; }
    100% { opacity: 0; top: 25px; }
}


.secondary-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 146px 0 56px 0;
    position: relative;
}

.secondary-hero .green-title {
    color: #52B382;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 56px;
}

.secondary-hero h1 {
    font-size: 4em;
    margin-bottom: 20px;
    color: #ffffff;    
}

.secondary-hero p {
    font-size: 1.4em;
    margin-bottom: 30px;
    color: #cccccc;
}

/* Center the iPad section -------------------------------------------------------------*/

#ipad {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}
#ipad-title {
    font-size: 3em;
    opacity: 0;
}

/* Image Container with Perspective */
#ipad .container {
    perspective: 750px;
    text-align: center;
}

.image-wrapper {
    position: relative;
    display: inline-block;
    transform-style: preserve-3d;
}

#ipad-image {
    height: 85vh;
    max-height: 1116px;
    width: auto;


    aspect-ratio: 1283  / 1670;
    transform-origin: center center;
    transition: transform 0.1s linear, mask-image 0.1s linear; /* Smooth rotation and mask transition */
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    background: url('../assets/iPad.jpg') no-repeat center;
    background-size: cover;
    background-position: center; 

    @include small() {
        height: auto;
        width: 90vw;
    }
}

#demo-scenarios {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
}

#demo-scenarios h3 {
    font-size: 1.8em; /* Adjust for larger title font */
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff; /* Add your color */
    @include small {
        font-size: 1.2em;
    }
    @include medium {
        font-size: 0.9em;
    }
}

#demo-scenarios .flex-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    flex-wrap: wrap; 
}

#demo-scenarios .flex-content span {
    font-size: 1em; /* Small font for the items */
    color: #cccccc; /* Adjust the text color */
    white-space: nowrap; /* Prevents line break for each item */
    @include small {
        font-size: 0.8em;
    }
    @include medium {
        font-size: 0.8em;
    }
}

/* Feature Section -------------------------------------------------------------------*/
#features {
    padding: 80px 0;
    position: relative;

    @include small {
        padding: 0px 0;
    }
}

.flex-horizontal {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: auto;
}

#features h2 {
    font-size: 3em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;

    @include small {
        font-size: 2em;
    }


}

#feature-desc {
    margin: 12px;
}

.features-grid {
    margin: 56px 0 0 0;
    display: flex;
    flex-wrap: wrap; 
    gap: 30px;
    justify-content: center
}

.feature-item {
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    max-width: 350px; 
    min-width: 330px; 
    text-align: left;
    border-radius: 24px;
    border: #333333 1px solid;
}

#seamless-api-feature {
    background: url('../assets/seamless-api.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#content-management-feature {
    background: url('../assets/content-management.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#self-serve-feature {
    background: url('../assets/self-serve.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#visual-interaction-feature {
    background: url('../assets/visual-interaction.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#emotion-analysis-feature {
    background: url('../assets/emotion-analysis.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#id-verification-feature {
    background: url('../assets/id-verification.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#reporting-feature {
    background: url('../assets/comprehensive-report.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#content-navigation-feature {
    background: url('../assets/visual-content.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}

#mobile-app-feature {
    background: url('../assets/user-mobile.jpg') no-repeat center;
    background-size: auto 296px;
    background-position: top;
}


.feature-item.in-view {
    opacity: 1;
    transform: translateY(0);
}


.feature-item h3 {
    margin-top: 308px;
    color: #ffffff;
    margin: 306px 16px 8px 16px;
    font-size: 1.2em;
}

.feature-item p {
    margin: 0px 16px 16px 16px;
}

/* How It Works Section *************************************************************************/
#how-it-works {
    padding: 96px 0;
    position: relative;
}

#how-it-works .full-width-div {
    position:absolute;
    width: 100%;
    display: block;
    height: 495px;
    background: radial-gradient(56.82% 75.15% at 51.13% 0%, #212121 0%, #171717 100%);
}

#how-it-works h2 {
    text-align: left;
    margin: 84px 0 50px 0;
    color: #ffffff;
    font-size: 2.5em;
    position: relative;
}


.how-it-works-list {
    position: relative;
}

.how-it-works-list div {
    position: relative;
    margin: 48px 0 0 0;
    padding-left: 50px;
    opacity: 0.35;
    transition: opacity 0.2s;
    cursor: pointer;
    width: 410px;
    
}

.how-it-works-list div.active {
    opacity: 1;
    cursor: default;
}

.how-it-works-list div::before {
    content: '';
    position: absolute;
    left: 4px;
    top: 6px;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    z-index: 1;
}

.how-it-works-list div.active::after {
    content: '';
    position: absolute;
    left: 11px;
    top: 6px;
    bottom: -54px;
    width: 1px;
    background: linear-gradient(to bottom, #F2F2F2, #666666);
    background-clip: padding-box; 
    z-index: 0;
}
.how-it-works-list div::after {
    content: '';
    position: absolute;
    left: 11px;
    top: 6px;
    bottom: -54px;
    width: 1px;
    background: #999999;
    background-clip: padding-box; 
    z-index: 0;
}


.how-it-works-list div:last-child::after {
    display: none; 
}

.how-it-works-list h3 {
    color: #ffffff;
    margin-bottom: 6px;
}

.how-it-works-list p {
    color: #8b949e;
    font-size: 0.8em;
}
.how-it-works-content {
    position: relative;
}

#how-it-works .how-it-works-content .content {
    display: block;
    position: absolute;
    bottom: -28px;
    right: 0;
    min-width: 557px;
    min-height: 660px;
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.4s, transform 0.4s ease;
}

#how-it-works .how-it-works-content .content.active {
    opacity: 1;
    transform: translateX(0) !important;
}

.how-it-works-content #step1 #wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 24px;
    border: 1px solid var(--Color-Grays-Gray-800, #333);
    background: linear-gradient(135deg, #252525 9.03%, #171717 74.14%);
    width: 100%;
    height: 100%;
    align-items: center;
}
.how-it-works-content #step1 #wrapper span {
    font-size: 1.5em;
    text-align: center;
    margin: 96px 32px 64px 32px;
}
.how-it-works-content #step1 a {
    padding: 20px 45px;
    background-color: #f2f2f2;
    color: #171717;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    margin: 32px 0 164px 0;
}

.how-it-works-content #step1 .footer div{
    font-size: 0.85em;
    background: url('../assets/important-icon.svg') no-repeat center;
    background-size: auto 16px;
    background-position: left;
    padding: 8px 0 8px 24px;
    margin: 0 32px 0 32px;
    color: #b3b3b3;
    border-top: #333333 1px solid;
}


#how-it-works #step2 div {
    display: block;
    min-width: 557px;
    min-height: 660px;
    position: absolute;
    background: url('../assets/integrate-content-1.svg') no-repeat center;
    background-position: top;
}

#how-it-works #step3 div {
    display: block;
    min-width: 557px;
    min-height: 660px;
    position: absolute;
    background: url('../assets/integrate-content-2.svg') no-repeat center;
    background-position: top;
}

#how-it-works #step4 div {
    display: block;
    min-width: 557px;
    min-height: 660px;
    position: absolute;
    background: url('../assets/Integrate-api.svg') no-repeat center;
    background-position: top;
}

#how-it-works #step5 div {
    display: block;
    min-width: 557px;
    min-height: 660px;
    position: absolute;
    background: url('../assets/launch.svg') no-repeat center;
    background-position: top;
}

#how-it-works #step6 div {
    display: block;
    min-width: 557px;
    min-height: 660px;
    position: absolute;
    background: url('../assets/analysis.svg') no-repeat center;
    background-position: top;
}

/* Benefits Section ***********************/
#benefits {
    padding: 80px 0;
    @include small {
        padding: 156px 0 80px 0;
    }
}

#benefits h2 {
    font-size: 3em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    width: auto;
    @include small {
        font-size: 2em;
    }
}

.benefits-grid {
    margin: 56px 0 56px 0;
    display: flex;
    flex-wrap: wrap; 
    gap: 30px; 
    justify-content: center; 
    @include small {
        margin: 0 0 56px 0;
    }
}

.benefit-item {
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    max-width: 350px; 
    min-width: 350px; 
    height: auto;
    text-align: left;
    border-radius: 24px;
    border: 1px solid var(--Color-Grays-Gray-800, #333);
    background: linear-gradient(135deg, #252525 9.03%, #171717 74.14%);
    padding: 24px;
}


.benefit-item h3 {
    color: #ffffff;
    margin-bottom: 8px;
    font-size: 1.5em;
}

.benefit-item p {
    color: #8b949e;
    font-size: 0.85em;
}

#benefits .dark-container {
    height: 291px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: auto;
}

#benefits #efficiency-a {
    background: url('../assets/efficiency-a.svg') no-repeat center;
    background-size: auto 291px;
    display: block;
    height: 291px;
    width: 307px;
}

#benefits #engagement-a {
    background: url('../assets/engagement-a.svg') no-repeat center;
    background-size: auto 189px;
    display: block;
    height: 189px;
    width: 307px;

}

#benefits #engagement-b {
    background: url('../assets/engagement-b.svg') no-repeat center;
    background-size: auto 69px;
    display: block;
    height: 69px;
    width: 307px;
}

#benefits #sales-a {
    background: url('../assets/sales-a.svg') no-repeat center;
    background-size: auto 291px;
    display: block;
    height: 291px;
    width: 307px;
}

#benefits #insights-a {
    background: url('../assets/insights-a.svg') no-repeat center;
    background-size: auto 126px;
    display: block;
    height: 126px;
    width: 307px;

}

#benefits #insights-b {
    background: url('../assets/insights-b.svg') no-repeat center;
    background-size: auto 152px;
    display: block;
    height: 152px;
    width: 307px;
}


#benefits #reach-a {
    background: url('../assets/reach-a.svg') no-repeat center;
    background-size: auto 291px;
    display: block;
    height: 291px;
    width: 307px;
}




#benefits #kyc-a {
    background: url('../assets/kyc-a.svg') no-repeat center;
    background-size: auto 291px;
    display: block;
    height: 291px;
    width: 307px;
}

#dataprivacy {
}

.dataprivacy-header {
    min-width: 312px;
    display: block;
}

.dataprivacy-header h2 {
    background: linear-gradient(90deg, #d7b000, #52B382);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 340px;
    font-size: 3em;
    @include small {
        font-size: 2em;
    }
}

.dataprivacy-header h3 {
    font-size: 1em;
    font-weight: 400;
    @include small {
        font-size: 0.8em;
    }
}

#trust {
margin-top: 86px;
}

/* Call to Action Section */
.cta-section {
    padding: 156px 0;
    text-align: center;
}

.cta-section h2 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 2.5em;
}

.cta-section p {
    margin: 8px 0 8px 0;
    color: #8b949e;
    margin-bottom: 30px;
    font-size: 0.8em;
}
.cta-section .action-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.cta-section .action {
    padding: 32px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    width: 312px;
    height: 297px;
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid var(--Color-Grays-Gray-800, #333);
    background: linear-gradient(135deg, #252525 9.03%, #171717 74.14%);
}
.cta-section .google {
    background: url('../assets/google.png') no-repeat left;
    background-size: auto 24px;
    display: block;
    padding-left: 32px;
    width: 126px;
    height: 24px;
    margin: 0px auto 0 auto;

}
.cta-section .apple {
    background: url('../assets/apple.png') no-repeat left;
    background-size: auto 24px;
    display: block;
    padding-left: 32px;
    width: 126px;
    height: 24px;
    margin: 16px auto 0 auto;
}
.cta-section .get-started {
    margin: 5px auto 5px auto;
    padding: 12px 25px;
    background-color: #f2f2f2;
    color: #171717;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    width: 208px;
}


.cta-section .call {
    background: url('../assets/nexi.png') no-repeat left;
    background-size: auto 32px;
    display: block;
    padding-left: 32px;
    width: 140px;
    height: 32px;
    margin: 16px auto 0 auto;
    line-height: 32px;
}

/* Pricing */
#pricing {
    padding: 80px 0 146px 0;
}

#pricing .pricing-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0;
    justify-content: center; 
    @include small() {
        flex-direction: column;
        gap: 156px;

    }

    @include medium() {
        flex-direction: column;
        gap: 156px;

    }   
}
#pricing .pricing-type {
    display: flex;
    flex-direction: column;
    flex: 1; 
    box-sizing: border-box;
    position: relative;


    position: relative;
    max-width: auto; 
    min-width: 350px; 

}

#pricing h2 {
    font-size: 3em;
    margin-bottom: 56px;
    @include small {
        font-size: 2em;
    }

}

#pricing h3 {
    font-size: 1.5em;
    margin-bottom: 32px;
    color: #cccccc;
    @include small() {
        margin-bottom: 16px;
    }
}

#pricing h4 {
    font-size: 1.5em;
    color: white;
}

#pricing .feature-list {
    position: relative;
    margin-top: 16px;
    padding-top: 16px;
    border-top: #4d4d4d 1px solid;
    display: flex;
    flex-direction: column;
    gap:12px;
}

#pricing .highlight::before {
    display: block;
    position: absolute;
    top: -175px;
    content: '';
    left: -36px;
    right: 0;
    bottom: -100px;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.08) 0%, rgba(36, 36, 36, 0.08) 100%);
    @include small() {
        background: none;
    }

    @include medium() {
        background: none;
    }
}

#pricing .feature-list span {
    color: #cccccc;
    padding-left: 28px;
    background: url('../assets/check.svg') no-repeat top left;
    background-size: auto 20px;
}
#pricing .feature-list span.plus {
    background: url('../assets/plus.svg') no-repeat top left;
    background-size: auto 20px;
}
#pricing .feature-list span.dots {
    background: url('../assets/dots.svg') no-repeat top left;
    background-size: auto 20px;
    font-style: italic;
}

#pricing .get-started {
    padding: 12px 25px;
    background-color: transparent;
    border: #333333 1px solid;
    color: #f2f2f2;
    border-radius: 6px;
    cursor: pointer;
    font-size: .75em;
    font-weight: 600;
    width: 124px;
    text-align: center;
    position: absolute;
    bottom: -64px;
    transition: background-color 0.2s ease-in-out;

}

#pricing .get-started:hover {
    background-color: #333;
}


/* Enterprise Support */

#enterprise-support {
    padding: 96px 0;
}

#enterprise-support  .container {
    position: relative;
}

#enterprise-support  .container::before{
    content: '';
    background: url('../assets/enterprise-support-banner.png') no-repeat right;
    background-size: auto 316px;
    display: block;
    padding-left: 32px;
    width: 566px;
    height: 316px;
    right: 0;
    position: absolute;
    @include small() {
        width: auto;
        height: 240px;

        background-size: cover;
        position: relative;
        border-radius: 12px;
        margin-bottom: 24px;
    }
    @include medium() {
        width: auto;
        background-size: cover;
        position: relative;
        border-radius: 12px;
        margin-bottom: 24px;
        height: 500px;
    }

}
#enterprise-support .container p {
    width: 496px;
    @include small() {
        width: auto;
    }
    @include medium() {
        width: auto;
    }
}

#enterprise-support .support-wrapper {
    position: relative;
    margin-top: 156px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include small() {
        margin-top: 56px;
    }
    @include medium() {
        margin-top: 56px;
    }
}

#enterprise-support .support-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    background: url('../assets/arrow-item.svg') no-repeat right;
    background-size: 18px 36px;
    width: 18px;
    height: 36px;
}

#enterprise-support .support-item {
    display: flex;
    flex-direction: column;
    flex: 1; 
    max-width: 100%;
    box-sizing: border-box;
    position: relative;
    border-bottom: #4d4d4d dashed 1px;
    padding: 0 0 32px 40px;
    margin-left: 12px;
}

#enterprise-support .support-item:last-child {
    border-bottom: none;
    padding: 0 0 0px 40px;
}

#enterprise-support h2 {
    color: white;
    font-size: 3em;
    margin-bottom: 16px;
    @include small() {
        font-size: 2em;
    }
}

#enterprise-support h3 {
    font-size: 1.5em;
    margin-bottom: 4px;
    color: white;
    @include small() {
        font-size: 1.2em;
    }
}

#enterprise-support .support-item p {
    width: 100%;
}


/* Footer Section */
footer {
    background-color: black;
    color: #8b949e;
    padding: 60px 0;
    font-size: 0.8em;
    display: block;
    height: 500px;
}

footer .container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

footer left {
    min-width: 400px;
    @include small() {
        padding-left: 32px;
        min-width: auto;
    }
    @include medium() {
        padding-left: 32px;
        min-width: auto;

    }

}
footer left {
    width: 100%;

}
footer .footer-subscribe-shortcut {
    width: 100%;
    display: block;
}

footer .footer-subscribe-shortcut p {
    width: 300px;
    color: #999999;
}
footer .logo {
    min-width: 90px;
    min-height: 44px;
    background: url('../assets/footer-logo.svg') no-repeat center;
}
footer .oruxa-logo {
    min-width: 45px;
    min-height: 13px;
    background: url('../assets/oruxa-logo.png') no-repeat center;
    background-size: 45px 13px;
    margin-left: 8px;
}
footer .footer-subscribe-shortcut span {
    line-height: 10px;
    color: #999999;

}

footer input {
    width: 300px;
    line-height: 32px;
    font-size: 1.25em;
    border: #333 1px solid;
    border-radius: 8px;
    background-color: transparent;
    padding: 4px;
    color: white;
    margin-bottom: 16px;
    @include small() {
        width: 200px;
    }
}

footer button {
    height: 40px;
    background-color: #333;
    color: #8b949e;
    width: 100px;
}

footer .row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}
footer .footer-page-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
}
footer .link-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

footer .link-section h3 {
    font-weight: bold;
}





/* ABOUT */

.about-sections .container {
    border-top: #333 1px solid;
    padding: 56px 0 56px 0;
}

.about-sections .flex-horizontal h2 {
    
    font-size: 2em;

}

.about-sections .flex-horizontal left {
    padding-right: 96px;
    min-width: 50%;
}

.about-sections .flex-horizontal right {
    min-width: 50%;
}

.team-image {
    width: 100%;
    height: 452px;
    background: url('../assets/about-team-1.png') no-repeat right;
    background-size: 487px 452px;

}





